import React from "react";
import img from "../assets/shrok-hero.png";

const Hero = () => {
  return (
    <div className="mt-[10vh]">
      <img src={img} alt="shrok-hero" className="w-full h-auto" />
    </div>
  );
};

export default Hero;
