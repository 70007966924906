import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./routes/Home";
import BackToHome from "./routes/BackToHome";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/*" element={<BackToHome />} />
      </Routes>
    </div>
  );
}

export default App;
