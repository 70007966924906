import React from "react";
import { motion } from "framer-motion";
import img from "../assets/logo/donke.jpeg";

const About = () => {
  return (
    <section
      id="About"
      className="w-full text-center bg-secondary text-black p-4 md:p-20"
    >
      <motion.h1
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, x: -50 },
          visible: { opacity: 1, x: 0 },
        }}
        className="text-6xl md:text-8xl font-bold my-8"
      >
        SHROK
      </motion.h1>

      <div className="md:flex justify-between items-center">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
          className="md:w-2/5 text-2xl text-center space-y-8 m-auto"
        >
          <h2>
            🟢 SHROK - The green orge comes to Solana! 🌳💰 <br />
            The only coin approved by Donke!
          </h2>
          <h2>Get ready to ogreload your wallet with SHROK!</h2>
          <h2>Dogs a lot, SHROK only one!</h2>
          <p className="text-lg break-all">
            Token: <br />
            3bX865qTHQa9VMu7R5KVVZdoccNEBAozmz4W78qM1EyG
          </p>
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: -50 },
            visible: { opacity: 1, y: 0 },
          }}
          className="md:w-2/5"
        >
          <img src={img} alt="Shrok" className="w-full m-auto" />
        </motion.div>
      </div>
    </section>
  );
};

export default About;
