import React from "react";
import img from "../assets/logo/shrokenomics.png";
import { motion } from "framer-motion";

const Tokenomics = () => {
  return (
    <section
      id="Tokenomics"
      className="w-full text-center p-10 md:p-20 bg-primary"
    >
      <h1 className="text-3xl md:text-8xl font-bold mb-12">SHROKENOMICS</h1>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, y: 50 },
          visible: { opacity: 1, y: 0 },
        }}
        className="md:flex justify-between items-center"
      >
        <div className="md:w-2/5">
          <img src={img} alt="Shrok" className="w-1/2 m-auto" />
        </div>
        <div className="md:w-2/5 text-2xl text-center space-y-8 m-auto">
          <h2>
            💎 Total Supply: <br />
            1,000,000 SHROK
          </h2>
          <h2>🔥 LP Tokens: Burnt</h2>
          <h2>🚫 Minting: Disabled</h2>
          <h2>🔒 Tax: 0%</h2>
          <h2>🔑 Contract Ownership: Renounced</h2>
        </div>
      </motion.div>
      <h1 className="text-2xl md:text-4xl font-bold my-12">DISTRIBUTION</h1>

      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, y: 50 },
          visible: { opacity: 1, y: 0 },
        }}
        className="md:flex justify-center gap-10 items-center text-xl"
      >
        <h2>💰 80% Liquidity Pool</h2>
        <h2>🎁 10% Airdrops</h2>
        <h2>📣 10% Marketing</h2>
        <h2>🌟 0% Devs/Team</h2>
      </motion.div>
      <p className="my-5 text-lg break-all">
        Airdrop Address: <br />
        8rjGpS124P4ihLasFRU1EjiNVpqfDxeahRnQXVxjr7Qx
      </p>
      <p className="text-lg break-all">
        Marketing Address: <br />
        8Xyb41gS4S3N2Ghp1ha3rvPNnfeTuktoMrSFDErWJ8GQ
      </p>
    </section>
  );
};

export default Tokenomics;
