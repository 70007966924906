import logo from "../assets/logo/logo512x512.png";
import { Link } from "react-router-dom";
import { FaXTwitter } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";

const Navbar = () => {
  return (
    <nav
      className={`fixed z-10 top-0 left-0 w-full h-[10vh] transition-all duration-300 bg-secondary bg-opacity-95 shadow-lg p-4 lg:px-[100px] flex justify-between items-center text-[22px] select-none`}
    >
      <Link to="/" className="">
        <img src={logo} alt="Shrok" className="h-[8vh]" />
      </Link>
      <ul className="flex space-x-6 lg:space-x-8 capitalize content-center justify-center">
        <li className="text-black hover:text-hover cursor-pointer m-auto">
          <a href="https://twitter.com/@ShrokOnSolana">
            <FaXTwitter />
          </a>
        </li>
        <li className="text-black hover:text-hover cursor-pointer m-auto">
          <a href="https://t.me/shrokonsolana">
            <FaTelegramPlane />
          </a>
        </li>
        <li className="text-black hover:text-hover cursor-pointer m-auto">
          <a href="https://raydium.io/swap?outputCurrency=3bX865qTHQa9VMu7R5KVVZdoccNEBAozmz4W78qM1EyG">
            BUY SHROK
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
