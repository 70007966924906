import React, { useState } from "react";
import { motion } from "framer-motion";
import img from "../assets/logo/shroked.png";

const HowToBuy = () => {
  const [address, setAddress] = useState("");

  const handleClick = () => {
    fetch("https://reqres.in/api/users", { address }).then((response) =>
      response.json()
    );
    alert("Added successfully!");
  };

  return (
    <section
      id="HowToBuy"
      className="w-full text-center p-10 md:p-20 bg-secondary text-black"
    >
      <motion.h1
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        className="text-4xl md:text-8xl font-bold mb-12"
      >
        How To Buy Shrok
      </motion.h1>
      <div className="md:flex justify-between items-center">
        <div className="md:w-2/5 text-2xl text-center space-y-8 m-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 1 }}
            className="text-lg my-8 mx-auto max-w-lg"
          >
            <h2 className="text-2xl font-bold">1. Install Solana Wallet:</h2>
            <p>Install Phantom or any Solana wallet to your browser.</p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 1 }}
            className="text-lg my-8 mx-auto max-w-lg"
          >
            <h2 className="text-2xl font-bold">2. Buy Solana:</h2>
            <p>Go to any exchange, connect your wallet and buy Sol.</p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 1 }}
            className="text-lg my-8 mx-auto max-w-lg"
          >
            <h2 className="text-2xl font-bold">3. Buy SHROK with SOL:</h2>
            <p>Connect your wallet to Raydium and buy SHROK with SOL.</p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 1 }}
            className="text-lg my-8 mx-auto max-w-lg"
          >
            <h2 className="text-2xl font-bold">4. HODL SHROK:</h2>
            <p>HODL SHROK OR GET BONKED. SHROK IS LOVE, SHROK IS LIFE!!!</p>
          </motion.div>
        </div>
        <div className="md:w-2/5">
          <img src={img} alt="Shrok" className="w-1/2 m-auto" />
        </div>
      </div>
      <div className="mt-10">
        <h2 className="text-lg">🎁 Give us your public wallet address:</h2>
        <div className="flex gap-1 items-center justify-center text-white">
          <input
            type="text"
            name="wallet"
            onChange={(e) => setAddress(e.target.value)}
            className="sm:w-1/3 md:w-1/4 my-3 rounded border border-gray-600 px-2 py-1 bg-primary bg-opacity-90 focus:bg-opacity-100"
          />
          <button
            onClick={handleClick}
            className="rounded border border-gray-600 px-2 py-1 bg-primary bg-opacity-90 hover:bg-opacity-100 hover:border-black"
          >
            Save
          </button>
        </div>
        <p>(There might be some special events in the future 😉)</p>
      </div>
    </section>
  );
};

export default HowToBuy;
