import React from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import About from "../components/About";
import Tokenomics from "../components/Tokenomics";
import HowToBuy from "../components/HowToBuy";
import Footer from "../components/Footer";
import Memes from "../components/Memes";

const Home = () => {
  return (
    <div className="bg-primary text-white h-[100%]">
      <Navbar />
      <Hero />
      <About />
      <Tokenomics />
      <HowToBuy />
      <Memes />
      <Footer />
    </div>
  );
};

export default Home;
