import React from "react";
import { motion } from "framer-motion";
import image1 from "../assets/memes/12d6e1ce98f4fd77e35addab22dcb784.jpg";
import image2 from "../assets/memes/168f9f8c939a35e63c9166187149e549.jpg";
import image3 from "../assets/memes/22d4ae9a3ebc7dfd5afcf26c0a13928e.jpg";
import image4 from "../assets/memes/4e93c3f6d343621c36dd75b209e47868.jpg";
import image5 from "../assets/memes/9debfaf4b301b0bde97372bc40d1c5e8.jpg";
import image6 from "../assets/memes/a312f833f7b9918c065e851c95f3cc88.jpg";
import image7 from "../assets/memes/aeb99d6e2572558c57b95a919fde0e15.jpg";
import image8 from "../assets/memes/c9df59d01995d9f6c76d270f03fab6db.jpg";
import image9 from "../assets/memes/db8.jpg";
import image10 from "../assets/memes/ef7491e161ca70748f6ddc211d0f9e39.jpg";
import image11 from "../assets/memes/flat,750x,075,f-pad,750x1000,f8f8f8.jpg";
import image12 from "../assets/memes/hqdefault.jpg";
import image13 from "../assets/memes/shrok-ork.png";
import image14 from "../assets/memes/sr23189d9151b3f.webp";
import image15 from "../assets/memes/1.jpg";
import image16 from "../assets/memes/2.jpg";
import image17 from "../assets/memes/3.jpg";
import image18 from "../assets/memes/4.jpg";
import image19 from "../assets/memes/5.jpg";
import image20 from "../assets/memes/6.jpg";

const Memes = () => {
  const images = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    image12,
    image13,
    image14,
    image15,
    image16,
    image17,
    image18,
    image19,
    image20,
  ];
  return (
    <section id="Contact" className="p-10 md:p-20">
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, x: 50 },
          visible: { opacity: 1, x: 0 },
        }}
        className="text-center"
      >
        <p className="text-4xl md:text-8xl mb-2">Memes</p>
      </motion.div>
      <div className="grid w-full grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10 mt-10">
        {images.map((image) => (
          <img
            src={image}
            alt="contact-us"
            key={image}
            className="w-[300px] h-[300px] m-auto rounded"
          />
        ))}
      </div>
    </section>
  );
};

export default Memes;
